
import { Options, Vue } from "vue-class-component";

import store, { ContentData, GraphData } from "@/store";
import { hideLoader, showLoader, startDownloadBlob, toast } from "@/tools";

import { watermark } from "@/graph";
import graphTypes from "@/graph/list";

import GraphControls from "@/components/GraphControls.vue";
import Loader from "@/components/Loader.vue";
@Options({
  components: {
    GraphControls,
    Loader,
  },
  watch: {
    contentData: {
      handler() {
        this.renderGraph();
      },
      deep: true,
    },
    graph: {
      handler() {
        this.renderGraph();
      },
      deep: true,
    },
  },
})
export default class Graph extends Vue {
  width = 0;
  height = 0;
  graphWrapper: HTMLElement | undefined;
  graphCanvas: HTMLCanvasElement | undefined;
  graphImg: HTMLImageElement | undefined;
  scaled = false;
  loading = false;

  mounted(): void {
    this.graphWrapper = document.getElementById("canvasWrapper") as HTMLElement;
    this.graphCanvas = document.getElementById(
      "graphCanvas"
    ) as HTMLCanvasElement;
    this.graphImg = document.getElementById("graph") as HTMLImageElement;
    this.resizeGraph();
    document.addEventListener("app-resize", (): void => {
      this.resizeGraph();
      this.loading = false;
    });
    document.addEventListener("app-resize-start", (): void => {
      this.loading = true; 
    });
  }

  resizeGraph(): void {
    if (this.graphWrapper == null) return;
    this.graph.scaleFactor = 1;
    if (this.size == 0) {
      // Auto
      this.width = this.graphWrapper.offsetWidth;
      this.height = this.graphWrapper.offsetHeight;
    } else if (this.size == 1) {
      // Auto - HighRes
      this.width = this.graphWrapper.offsetWidth * 5;
      this.height = this.graphWrapper.offsetHeight * 5;
      this.graph.scaleFactor = 5;
    } else if (this.size == 2) {
      // Standard
      this.width = 800;
      this.height = 600;
    } else if (this.size == 3) {
      // Small
      this.width = 500;
      this.height = 400;
    } else if (this.size == 4) {
      // Short
      this.width = 800;
      this.height = 300;
    }
    this.scaled = this.graph.scaleFactor == 5;
    this.renderGraph();
  }

  renderGraph(): void {
    if (this.graphImg == null || this.graphCanvas == null) {
      return;
    }
    const ctx: CanvasRenderingContext2D = this.graphCanvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;
    const canvas = ctx.canvas;
    canvas.width = this.width;
    canvas.height = this.height;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.imageSmoothingEnabled = true;
    ctx.fillStyle = "#ffffff";
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fill();

    const graph = graphTypes[this.graphType];
    graph.func(ctx);
    watermark(ctx, ctx.canvas.width, ctx.canvas.height);
    const data = canvas.toDataURL();
    this.graphImg.src = data;
  }

  resetMap(): void {
    const map: HTMLMapElement = document.getElementById(
      "canvasMap"
    ) as HTMLMapElement;
    map.innerHTML = "";
  }

  download(): void {
    const graphCanvas: HTMLCanvasElement = document.getElementById(
      "graphCanvas"
    ) as HTMLCanvasElement;
    showLoader("Downloading Image");
    graphCanvas.toBlob((data: Blob | null) => {
      if (!data) {
        toast("Failed to create image download", "error");
      } else {
        startDownloadBlob(data, "graph.png");
        hideLoader();
      }
    });
  }

  get size(): number {
    return store.state.graph.size;
  }

  get graphType(): string {
    return store.state.graph.type;
  }

  get graph(): GraphData {
    return store.state.graph;
  }

  get contentData(): ContentData {
    return store.state.data;
  }
}
