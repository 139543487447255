
import store, { RowData, SelectedData } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    dMoveTo() {
      this.scrollToRow(store.state.data.moveTo);
    },
  },
})
export default class ContentTable extends Vue {
  displayedRows = 50;
  lastScrollPoint = 0;

  editColumnIndex = -1;
  editRowIndex = -1;
  editRowColumnIndex = -1;

  editColumn(index: number): void {
    this.editColumnIndex = index;
    this.editRowIndex = -1;
    this.editRowColumnIndex = -1;
    this.$nextTick(function () {
      const element: HTMLElement | null = document.querySelector(
        `.col-input[data-col="${this.editColumnIndex}"]`
      );
      if (!element) return;
      element.focus();
    });
  }

  editRow(index: number, column: number): void {
    this.editRowIndex = index;
    this.editRowColumnIndex = column;
    this.editColumnIndex = -1;
    this.$nextTick(function () {
      const element: HTMLElement | null = document.querySelector(
        `.row-input[data-col="${this.editRowColumnIndex}"][data-row="${this.editRowIndex}"]`
      );
      if (!element) return;
      element.focus();
    });
  }

  get rows(): RowData[][] {
    const rows = store.state.data.rows;
    if (rows.length > this.displayedRows) {
      return rows.slice(0, this.displayedRows);
    } else {
      return rows;
    }
  }

  set rows(value: RowData[][]) {
    store.state.data.rows = value;
  }

  get selected(): SelectedData {
    return store.state.data.selected;
  }

  get cols(): string[] {
    return store.state.data.cols;
  }

  set cols(value: string[]) {
    store.state.data.cols = value;
  }

  get dMoveTo(): number {
    return store.state.data.moveTo;
  }

  scrollToRow(row: number): void {
    if (row == -1) return;
    if (row > this.displayedRows) {
      this.displayedRows = row + 5;
    }
    store.state.data.selected.row = row;
    this.$nextTick(function () {
      const element: HTMLElement | null = document.querySelector(
        `tr[data-row="${row}"]`
      );
      if (element == null) return;
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    });
  }

  select(rowIndex: number, colIndex: number): void {
    store.state.data.selected = {
      col: colIndex,
      row: rowIndex,
    };
  }

  mounted(): void {
    this.$el.onscroll = () => {
      var sh = this.$el.scrollHeight;
      var st = this.$el.scrollTop;
      var ht = this.$el.offsetHeight;
      if (ht == 0 || st >= sh - ht) {
        this.displayedRows += 10;
      }
    };
  }
}
