
import store, { Toast } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    toasts: {
      handler() {
        this.createTimeouts();
      },
      deep: true,
    },
  },
})
export default class Loader extends Vue {
  get toasts(): Toast[] {
    return store.state.toasts;
  }
  set toasts(value: Toast[]) {
    store.state.toasts = value;
  }

  createTimeouts(): void {
    for (const toast of this.toasts) {
      if (!toast.timeout) {
        toast.timeout = setTimeout(() => {
          this.toasts = this.toasts.filter((t) => t.id != toast.id);
        }, toast.duration);
      }
    }
  }
}
