
import store, { ContentData, GraphData, RowData, SelectedData } from "@/store";
import { Options, Vue } from "vue-class-component";
import graphList from "@/graph/list";
import Dialog from "@/components/Dialog.vue";
import {
  dataToCSV,
  importCSVFromURL,
  importFromClipboard,
  importFromFile,
  insertIndexX,
  insertIndexY,
  removeIndexX,
  removeIndexY,
  startDownloadBlob,
  toast,
} from "@/tools";
import { addDefaultSettings } from "@/graph";
import graphTypes from "@/graph/list";

interface ToolbarItem {
  icon?: string;
  name?: string;
  group?: boolean;
  action?(): void;
  children?: ToolbarItem[];
  expanded?: boolean;
}

@Options({
  components: { Dialog },
  watch: {
    graphType() {
      addDefaultSettings(graphTypes[this.graphType].settings);
    },
  },
})
export default class Header extends Vue {
  pickURLDialog = false;
  importURL = "";

  importFromURL(): void {
    var regex = /^(ftp|http|https):\/\/[^ "]+$/;
    if (this.importURL.length < 1) return alert("No URL provided!");
    if (!regex.test(this.importURL)) return alert("Invalid url provided");
    this.pickURLDialog = false;
    importCSVFromURL(this.importURL)
      .then(() => {
        this.importURL = "";
        const rows = store.state.data.rows;
        alert(`Imported ${rows.length}row(s)`);
      })
      .catch(() => alert("Failed to import csv"));
  }

  expanded = false;

  graphs = graphList;

  set graphType(value: string) {
    store.state.graph.type = value;
  }
  get graphType(): string {
    return store.state.graph.type;
  }

  get graphData(): GraphData {
    return store.state.graph;
  }

  set graphData(value: GraphData) {
    store.state.graph = value;
  }

  set title(value: string) {
    store.state.graph.title = value;
  }

  get title(): string {
    return store.state.graph.title;
  }

  toolbar: ToolbarItem[] = [
    {
      name: "Data",
      children: [
        {
          icon: "delete",
          name: "Clear Data",
          action: (): void => {
            if (
              confirm(
                "Are you sure you want to clear the current data table? Make sure to download this data first because it will not be saved."
              )
            ) {
              store.state.data.rows = [[""]];
              store.state.data.cols = [""];
              store.state.data.selected = { row: -1, col: -1 };
            }
          },
        },
        {
          icon: "file_upload",
          name: "Open File",
          action: (): void => {
            importFromFile();
          },
        },
        {
          icon: "link",
          name: "Import from URL",
          action: (): void => {
            this.pickURLDialog = true;
          },
        },
        {
          icon: "content_paste",
          name: "Import from Clipboard",
          action: (): void => {
            importFromClipboard();
          },
        },

        { icon: "highlight_alt", name: "Select & Copy Data Table" },
        {
          icon: "file_download",
          name: "Download Data",
          action: (): void => {
            const csvData: string = dataToCSV();
            const dataBlob: Blob = new Blob([csvData]);
            startDownloadBlob(
              dataBlob,
              this.title.replaceAll(" ", "_") + ".csv"
            );
          },
        },
        { icon: "save_alt", name: "Save Session" },
      ],
    },
    {
      name: "Edit",
      children: [
        {
          name: "Remove Row",
          action(): void {
            const data: ContentData = store.state.data;
            const selected: SelectedData = store.state.data.selected;
            const row: number = selected.row;
            if (row != -1) {
              data.rows = removeIndexX(data.rows, row);
              toast(`Removed row ${row}`);
              data.selected = { row: -1, col: -1 };
            } else {
              toast("No row selected!", "error");
            }
          },
        },
        { name: "Remove Specific Row" },
        {
          name: "Remove Last Row",
          action(): void {
            const data: ContentData = store.state.data;
            const rows: RowData[][] = data.rows;
            if (rows.length < 1) return;
            data.rows = data.rows.slice(0, data.rows.length - 1);
            toast("Removed last row");
          },
        },
        {},
        {
          name: "Remove Column",
          action(): void {
            const data: ContentData = store.state.data;
            const col: number = data.selected.col;
            if (col != -1) {
              const columnName: string = data.cols[col];
              data.rows = removeIndexY(data.rows, col);
              data.cols = removeIndexX(data.cols, col);
              toast(`Removed Column "${columnName}"`);
              data.selected = { row: -1, col: -1 };
            } else {
              toast("No column selected!", "error");
            }
          },
        },
        {
          name: "Remove Last Column",
          action(): void {
            const data: ContentData = store.state.data;
            const col: number = data.cols.length - 1;
            const columnName: string = data.cols[col];
            data.rows = removeIndexY(data.rows, col);
            data.cols = removeIndexX(data.cols, col);
            toast(`Removed Column "${columnName}"`);
          },
        },
      ],
    },
    {
      name: "Insert",
      children: [
        {
          name: "Insert Row",
          action(): void {
            toast("Inserted row");
            store.state.data.rows.push(new Array(store.state.data.cols.length));
          },
        },
        {
          name: "Insert Row Before",
          action(): void {
            const data: ContentData = store.state.data;
            const selected: SelectedData = store.state.data.selected;
            const row: number = selected.row;
            if (row != -1) {
              data.rows = insertIndexX<RowData[]>(
                new Array(store.state.data.cols.length),
                data.rows,
                row - 1
              );
              toast(`Inserted row after ${row}`);
              data.selected.row++;
            } else {
              toast("No row selected!", "error");
            }
          },
        },
        {
          name: "Insert Row After",
          action(): void {
            const data: ContentData = store.state.data;
            const selected: SelectedData = store.state.data.selected;
            const row: number = selected.row;
            if (row != -1) {
              data.rows = insertIndexX<RowData[]>(
                new Array(store.state.data.cols.length),
                data.rows,
                row
              );
              toast(`Inserted row after ${row}`);
            } else {
              toast("No row selected!", "error");
            }
          },
        },
        {},
        {
          name: "Insert Column",
          action(): void {
            toast("Inserted column");
            store.state.data.cols.push("");
          },
        },
        {
          name: "Insert Column Before",
          action(): void {
            const data: ContentData = store.state.data;
            const selected: SelectedData = store.state.data.selected;
            const col: number = selected.col;
            if (col != -1) {
              data.cols = insertIndexX<string>("", data.cols, col - 1);
              data.rows = insertIndexY("", data.rows, col - 1);
              toast(`Inserted column after ${col}`);
              data.selected.col++;
            } else {
              toast("No column selected!", "error");
            }
          },
        },
        {
          name: "Insert Column After",
          action(): void {
            const data: ContentData = store.state.data;
            const selected: SelectedData = store.state.data.selected;
            const col: number = selected.col;
            if (col != -1) {
              data.cols = insertIndexX<string>("", data.cols, col);
              data.rows = insertIndexY("", data.rows, col);
              toast(`Inserted column after ${col}`);
            } else {
              toast("No column selected!", "error");
            }
          },
        },
      ],
    },
  ];

  expand(item: ToolbarItem): void {
    item.expanded = true;
    this.expanded = true;
  }

  closeAll(): void {
    this.expanded = false;
    for (const other of this.toolbar) {
      other.expanded = false;
    }
  }

  action(item: ToolbarItem): void {
    if (item.action) {
      item.action();
      this.closeAll();
    }
  }
}
