
import { Options, Vue } from "vue-class-component";
import storage from "@/storage";

import Dialog from "@/components/Dialog.vue";

@Options({
  components: { Dialog },
})
export default class About extends Vue {
  open = true;
  hideNext = false;

  get show(): boolean {
    const showAbout: boolean = storage.bool("show-about", true);
    if (!showAbout) {
      return false;
    }
    return this.open;
  }

  hideAbout(): void {
    storage.set("show-about", !this.hideNext);
    this.open = false;
  }
}
