import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3879e6bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog" }
const _hoisted_2 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "dialog-wrapper" }, {
    default: _withCtx(() => [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "dialog-wrapper",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeDialog && _ctx.closeDialog(...args)), ["self"]))
          }, [
            _createVNode(_Transition, { name: "dialog" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                  _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
                  ])
                ])
              ]),
              _: 3
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}