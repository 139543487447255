
import { Options, Vue } from "vue-class-component";
import store, { ContentData, GraphData } from "@/store";
import graphTypes from "@/graph/list";
import { GraphTypeData } from "@/graph/types";

@Options({
  props: ["resizeGraph"],
  emits: ["resize"],
})
export default class GraphControls extends Vue {
  width = 0;
  height = 0;
  controls = true;

  toggleControls(): void {
    this.controls = !this.controls;
    this.$nextTick(function () {
      this.$emit("resize");
    });
  }

  get graphData(): GraphData {
    return store.state.graph;
  }

  set graphData(value: GraphData) {
    store.state.graph = value;
  }

  get graphType(): GraphTypeData {
    return graphTypes[this.graphData.type];
  }

  get size(): number {
    return store.state.graph.size;
  }

  set size(value: number) {
    store.state.graph.size = value;
  }

  get contentData(): ContentData {
    return store.state.data;
  }
}
