import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61afb3e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "resizable" }
const _hoisted_2 = {
  class: "resizable__content",
  "data-position": "left"
}
const _hoisted_3 = {
  class: "resizable__content",
  "data-position": "right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "left", {}, undefined, true)
    ]),
    _createElementVNode("span", {
      class: "resizable__handle",
      onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startResize && _ctx.startResize(...args)))
    }, null, 32),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ]))
}